import { getRequest, postRequest, putRequest, deleteRequest } from "./request";

export const initMenu = (router, store, path) => {
    getRequest('/permis/menu/uid').then(data => {
        if (data) {
            //格式化路由
            let fmtRoutes = formatRoutes(data);
            //将菜单数据存入vuex
            store.commit('initRoutes', fmtRoutes);
            //添加到router
            fmtRoutes.forEach(item => {
                if (!router.hasRoute(item.name)) {
                    router.addRoute({
                        path: item.path,
                        name: item.name,
                        component: item.component,
                        children: item.children,
                        meta: item.meta
                    });
                }
            });
            //console.log(router.getRoutes());
            router.replace(path);
        }
    })
}

export const formatRoutes = (data) => {
    let fmtRoutes = [];
    data.forEach(item => {
        let {
            parentId,
            path,
            linkUrl,
            iconUrl,
            name,
            menuCode,
            children,
            keepAlive,
        } = item;
        if (children && children instanceof Array) {
            //递归
            children = formatRoutes(children)
        }
        let fmtRoute = {
            name: menuCode,
            path: path,
            meta: {
                iconUrl: iconUrl,
                title: name,
                keepAlive: keepAlive,
                parent: linkUrl.split('/')[1].toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase()),
            },
            children: children,
            component() {
                if (parentId == 0) {
                    return import("../views/layout/Index.vue");
                } else {
                    return import("../views" + linkUrl + "/" + menuCode + ".vue");
                }
            }
        }
        fmtRoutes.push(fmtRoute);
        //console.log(fmtRoute);
        //router.addRoute(fmtRoute.meta.pid, fmtRoute);
    });
    return fmtRoutes;
}

export const getMenuTreeList = (res) => {
    return getRequest('/permis/menus', res)
}

export const getMenuByRid = (rid) => {
    return getRequest('/permis/role/menu/' + rid, rid)
}

export const updateRoleMenus = (res) => {
    return postRequest('/permis/role/menu', res)
}

export const getMainMenuList = (res) => {
    return getRequest('/permis/menu/main', res)
}

export const getSubMenuList = (pid) => {
    return getRequest('/permis/menu/sub/' + pid, pid)
}

export const updateMenuStatus = (res) => {
    return putRequest('/permis/menu/status', res)
}

export const updateMenuCache = (res) => {
    return putRequest('/permis/menu/cache', res)
}

export const saveMenu = (res) => {
    if (res.id) {
        //更新菜单
        return putRequest('/permis/menu', res)
    }
    else {
        //添加菜单
        return postRequest('/permis/menu', res)
    }
}

export const deleteMenu = (mid) => {
    return deleteRequest('/permis/menu/' + mid, mid)
}

