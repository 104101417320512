<template>
  <!-- vue3.0配置 -->
  <router-view> </router-view>
</template>

<style lang="scss">
#app {
  margin: auto;
}
</style>
