import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Login",
    meta: {
      title: "登录",
      iconUrl: "sign-out",
    },
    component: () => import("../views/user/Login.vue"),
  },
  {
    path: "/forget",
    name: "Forget",
    meta: {
      title: "忘记密码",
      iconUrl: "sign-out",
    },
    component: () => import("../views/system/pwd/PwdSettings.vue"),
  }
  // {
  //   path: "/index",
  //   name: "Index",
  //   meta: {
  //     title: "系统管理",
  //     iconUrl: "system",
  //   },
  //   component: () => import("../views/layout/Index.vue")
  // },
  // {
  //   path: "/index",
  //   name: "Index",
  //   meta: {
  //     title: "系统管理",
  //     iconUrl: "system",
  //   },
  //   component: () => import("../views/layout/Index.vue"),
  //   children: [
  //     {
  //       path: "/dict",
  //       name: "Dict",
  //       meta: {
  //         title: "数据字典设置",
  //         iconUrl: "system",
  //       },
  //       component: () => import("../views/system/dict/Dict.vue"),
  //       children: []
  //     },
  //     {
  //       path: "/syslog",
  //       name: "Syslog",
  //       meta: {
  //         title: "系统日志查询",
  //         iconUrl: "syslog",
  //       },
  //       component: () =>
  //         import("../views/system/syslog/Syslog.vue"),
  //       children: []
  //     }
  //   ]
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
