import { postRequest, getRequest, putRequest, deleteRequest } from './request'
import { downloadRequest } from "@/api/download";
export const Login = (res) => {
    return postRequest('/login', res)
}

export const Logout = (res) => {
    return postRequest('/logout', res)
}

export const getCurrentUser = (res) => {
    return getRequest('/user/current', res)
}

//验证当前用户密码是否正确
export const checheUserPassword = (pwd) => {
    return getRequest('/user/user/pwd/' + pwd)
}

export const getRoleByUserId = (uid) => {
    return getRequest('/user/user/role/' + uid, uid)
}

export const getUserListByRoleId = (rid) => {
    return getRequest('/user/user/' + rid)
}

export const getUserListPage = (res) => {
    return postRequest('/user/users', res)
}

export const saveUser = (res) => {
    if (res.mode == 1) {
        //更新用户
        return putRequest('/user/user', res.ui)
    }
    if (res.mode == 0) {
        //添加用户
        return postRequest('/user/user', res.ui)
    }
    if (res.mode == 2) {
        //注册用户
        return postRequest('/user/register', res.ui)
    }
}

export const updateUserStatus = (res) => {
    return putRequest('/user/user/status', res)
}

export const updateUserPassword = (res) => {
    return putRequest('/user/user/pwd', res)
}

export const deleteUser = (uid) => {
    return deleteRequest('/user/user/' + uid, uid)
}

export const deleteUsers = (ids) => {
    return deleteRequest('/user/user', ids)
}

export const exportUsers = (uname) => {
    return downloadRequest('/user/user/export', uname)
}

export const importUsers = (uname) => {
    return postRequest('/user/user/import', uname)
}

export const updateUserRole = (res) => {
    return postRequest('/user/user/role/', res)
}

export const uploadUserAvatar = (res) => {
    return postRequest('/user/user/avatar', res)
}

export const updateUserAvatar = (res) => {
    return putRequest('/user/user/avatar', res)
}

export const getAppUserListPage = (res) => {
    return postRequest('/appuser/users', res)
}

export const updateAppUserStatus = (res) => {
    return putRequest('/appuser/user/status', res)
}

export const getAppUserKeysPage = (res) => {
    return postRequest('/appuser/user/key', res)
}


export default {
    Login,
    getCurrentUser,
    checheUserPassword,
    getRoleByUserId,
    getUserListPage,
    saveUser,
    updateUserStatus,
    updateUserPassword,
    deleteUser,
    deleteUsers,
    exportUsers,
    importUsers,
    updateUserRole,
    getUserListByRoleId,
    uploadUserAvatar,
    updateUserAvatar,
    getAppUserListPage,
    updateAppUserStatus,
    getAppUserKeysPage
}