import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { initMenu } from "./api/menu";
import Moment from 'moment'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import Prism from 'prismjs';

VueMarkdownEditor.use(vuepressTheme, {
    Prism,
});

const app = createApp(App)

app.config.globalProperties.$filter = {
    formatDate(value) {
        return Moment(value).format('YYYY-MM-DD HH:mm:ss');
    }
}

app.config.globalProperties.resetSetItem = function (key, newVal) {
    //这个key就是我们要监听的那个key
    if (key === 'userinfo') {
        // 创建一个StorageEvent事件
        var newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: function (k, val) {
                //这里可以灵活更改local和session
                sessionStorage.setItem(k, val);
                // 初始化创建的事件
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
                // 派发对象
                window.dispatchEvent(newStorageEvent)
            }
        }
        return storage.setItem(key, newVal);
    }
}

app
    .use(store)
    .use(router)
    .use(Antd)
    .use(VueMarkdownEditor)
    .mount("#app");

router.beforeEach((to, from, next) => {
    if (sessionStorage.getItem("token")) {
        if (store.state.routes.length > 0) {
            next();
        }
        else {
            initMenu(router, store, to.path);
        }

    } else {
        next();
    }
});
