import axios from "axios";
import { message } from 'ant-design-vue';
import router from "../router"

//请求拦截器
// axios.interceptors.request.use(config => {
//     //如果存在这个token请求携带这个tioken
//     if (window.sessionStorage.getItem("token")) {
//         config.headers[Authorization] = window.sessionStorage.getItem("token");
//     }
//     return config;
// }, error => {
//     console.log(error);
// });

axios.interceptors.request.use(config => {
    //config是axios配置对象
    //每次发送请求前都会进入此拦截器处理函数，可以在此处统一携带上token，每次请求都会自
    config.headers['Authorization'] = sessionStorage.getItem('token');
    return config
}, err => {
    //请求出错的处理函数
    return Promise.reject(err)
})




//响应拦截器
axios.interceptors.response.use(success => {
    //业务逻辑错误
    if (success.status && success.status == 200) {
        if (success.data.code == 500 || success.data.code == 401 || success.data.code == 403) {
            message.error(success.data.message);
            router.replace("/");
            return;
        }
        // if (success.data.message) {
        //     message.success(success.data.message);
        // }
    }
    return success.data;
}, error => {
    if (error.response.status == 500 || error.response.status == 404) {
        message.error("服务器繁忙，请稍后再试！");
    } else if (error.response.status == 403) {
        message.error("权限不足，请联系管理员！");
    } else if (error.response.status == 401) {
        message.error("尚未登录，请登录！");
        router.replace('/');
    } else {
        if (error.response.statusText) {
            message.success(error.response.statusText);
        } else {
            message.error("未知错误！");
        }
    }
    return;
});

let base = '/api';
//let base = '';

export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params
    })
}



export const getRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        data: params
    })
}

export const putRequest = (url, params) => {
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params
    })
}

export const deleteRequest = (url, params) => {
    return axios({
        method: 'delete',
        url: `${base}${url}`,
        data: params
    })
}