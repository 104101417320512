import { createStore } from "vuex";
import { Login, getCurrentUser, Logout } from '@/api/user'
import router from "../router"
export default createStore({
  state: {
    token: '',
    userInfo: {},
    routes: [],
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USERINFO: (state, userinfo) => {
      state.userInfo = userinfo;
    },
    initRoutes: (state, routes) => {
      state.routes = routes;
    },
    addTab(state, tab) {
      let tabs = JSON.parse(sessionStorage.getItem("tabs"));
      tabs.push(tab);
      sessionStorage.setItem("tabs", JSON.stringify(tabs));
    },
    closeTab(state, indexNum) {
      let tabs = JSON.parse(sessionStorage.getItem("tabs"));
      tabs.splice(indexNum, 1);
      sessionStorage.setItem("tabs", JSON.stringify(tabs));
    },
    switchTab(state, nowIndex) {
      sessionStorage.setItem("activeKey", JSON.stringify({ key: nowIndex }))
    }

  },
  actions: {
    LoginResult({ commit }, userinfo) {
      return new Promise((resolve, reject) => {
        Login(userinfo).then(response => {
          if (response) {
            const { code, obj } = response
            if (code == 200) {
              //保存token到store
              let token = obj.tokenHeader + obj.token
              sessionStorage.removeItem("token");
              sessionStorage.setItem('token', token)
              commit('SET_TOKEN', token)
              //获取用户信息
              getCurrentUser().then(data => {
                if (data) {
                  let userinfo = data
                  sessionStorage.removeItem("userinfo")
                  sessionStorage.setItem('userinfo', JSON.stringify(userinfo))
                  commit('SET_USERINFO', userinfo)
                  resolve(data)
                  //登录成功跳转到主页
                  router.replace('/dashboard');
                }
              }).catch(error => {
                reject(error)
              })
            }
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    LogoutResult({ commit }) {
      return new Promise((resolve, reject) => {
        Logout().then(response => {
          if (response) {
            const { code } = response
            if (code == 200) {
              //清除session和store
              sessionStorage.clear()
              commit('SET_TOKEN', '')
              commit('SET_USERINFO', {})
              commit('initRoutes', [])
              //跳转到登录页
              router.replace('/');
            }
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    clickMenuItem({ commit }, path) {
      let key = path.slice(1)
      let tabs = JSON.parse(sessionStorage.getItem("tabs"));
      if (key !== 'dashboard') {
        var tab = tabs.find(f => f.key === key)
        if (!tab) {
          let menu = {}
          menu = this.state.routes.find(f => f.path === path)
          if (!menu) {
            menu = this.state.routes.map(a => a.children).flat().find(f => f.path === path)
          }
          let newTab = {
            title: menu.meta.title,
            key: key,
            closable: true,
            parent: menu.meta.parent,
          }
          commit('addTab', newTab)
        }
      }
      commit('switchTab', key)
      router.push(path)
    },
    closeTab({ commit }, key) {
      let tabs = JSON.parse(sessionStorage.getItem("tabs"))
      let indexNum = tabs.findIndex(f => f.key === key)
      commit('closeTab', indexNum)
      let oldActiveKey = JSON.parse(sessionStorage.getItem("activeKey")).key
      if (oldActiveKey === key) {
        let newActivekey = indexNum === 0 ? 'dashboard' : tabs[indexNum - 1].key
        sessionStorage.setItem("selectedKeys", "/" + newActivekey)
        sessionStorage.setItem("openKeys", tabs[indexNum - 1].parent)
        commit('switchTab', newActivekey)
        router.push("/" + newActivekey)
      }
    },
  },
  modules: {},
});
